<template lang="html">
  <div>
    <div class="title-area mt-s">
      <h2 class="title-b">설정 / 펌웨어 등록</h2>
    </div>
    <div class="title-area mt-s">
      <h3 class="title-a">단말기 펌웨어</h3>
      <div class="btn-item">
        <button type="button" class="element-btn bg-blue" @click="displayFirmwareRegist()"><i class="icon-img ic-btn-add"></i>파일 업데이트</button>
      </div>
    </div>
    <div class="data-box mt-s">
      <dl class="data-item">
        <dt class="data-title">버전</dt>
        <dd class="data-value">v{{firmwareInfo.device.firmwareVersion}}</dd>
      </dl>
      <dl class="data-item">
        <dt class="data-title">용량</dt>
        <dd class="data-value">{{firmwareInfo.device.fileSize | byteToString}}</dd>
      </dl>
      <dl class="data-item">
        <dt class="data-title">업로드 일자</dt>
        <dd class="data-value">{{firmwareInfo.device.insertDate | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
      </dl>
      <dl class="data-item">
        <dt class="data-title">파일</dt>
        <dd class="data-value">
          <div class="file-box">
            <i class="icon-img ic-firmware"></i>
            <span class="link-firmware">{{firmwareInfo.device.fileOrgName}}</span>
          </div>
        </dd>
      </dl>
    </div>

    <div class="title-area mt-m">
      <h3 class="title-a">WiFi DB</h3>
    </div>
    <div class="data-box mt-s">
      <dl class="data-item">
        <dt class="data-title">버전</dt>
        <dd class="data-value">v{{firmwareInfo.wifi.firmwareVersion}}</dd>
      </dl>
      <dl class="data-item">
        <dt class="data-title">용량</dt>
        <dd class="data-value">{{firmwareInfo.wifi.fileSize | byteToString}}</dd>
      </dl>
      <dl class="data-item">
        <dt class="data-title">업로드 일자</dt>
        <dd class="data-value">{{firmwareInfo.wifi.insertDate | moment('YYYY-MM-DD HH:mm:ss')}}</dd>
      </dl>
      <dl class="data-item">
        <dt class="data-title">파일</dt>
        <dd class="data-value">
          <div class="file-box">
            <i class="icon-img ic-firmware"></i>
            <span class="link-firmware">{{firmwareInfo.wifi.fileOrgName}}</span>
          </div>
        </dd>
      </dl>
    </div>
    <div class="page-btn-area">
      <!-- <button type="button" class="element-btn bg-blue size-lg">확인</button> -->
    </div>







    <div class="module-dimmed" v-if="showFirmwareRegist">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">펌웨어 파일 등록</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="form-group">
              <div class="form-item">
                <span class="form-label">파일 종류 <em class="essential">*</em></span>
                <div class="form-inp">
                  <div class="element-dropdown_lg">
                    <t-rich-select
                      :options="firmwareTypeList"
                      class="border border-solid rounded-md text-gray-700 border-gray-300 outline-none w-62"
                      textAttribute="value"
                      valueAttribute="id"
                      :hideSearchBox="true"
                      v-model="firmwareType"
                      :fixedClasses="richSelectXL"
                      @change="changeFirmwareType"
                    >
                    </t-rich-select>
                  </div>
                </div>
              </div>
              <div class="form-item">
                <span class="form-label">펌웨어 파일 업로드 <em class="essential">*</em></span>
                <div class="form-inp">
                  
                  <div v-if="firmwareFileInfo" class="mt-5 px-4 py-4 bg-white border border-sm-light-periwinkle border-dotted rounded-xl w-full">
                    <div class="text-sm font-bold txt-blue cursor-pointer text-right mt-1 mb-5" @click="retryFirmwareFile()">새로올리기</div>
                    <div class="block text-center mb-2">
                      <div class="flex justify-center items-center">
                        <i class="icon-img ic-firmware mr-3"></i>
                      <div>{{ firmwareFileInfo.name }}</div>
                      </div>
                    </div>
                  </div>
                  
                  <div v-else class="box-file-upload" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input type="file" class="w-px h-px opacity-0 overflow-hidden absolute" @change="registFirmwareFile()" id="firmwareFile" ref="firmwareFile" accept=".bin" />
                  
                    <label for="firmwareFile" class="block cursor-pointer">
                      <div class="flex flex-col justify-center items-center">
                        <i class="icon-img ic-m-file-upload"></i>
                        <span class="file-desc">이곳을 클릭하여 파일 업로드 or 파일 드래그</span>
                        <span class="file-name">지원 파일 확장자 *.bin</span>
                      </div>
                    </label>
                  </div>
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="!firmwareFileInfo">필수 입력 사항 입니다.</p>

                </div>
              </div>

              <div class="form-item">
                <span class="form-label">버전 번호 <em class="essential">*</em></span>
                <div class="form-inp" v-if="firmwareType=='device'">
                  <!-- <input type="text" class="element-input size-lg"> -->
                  <div class="flex justify-center items-center border border-solid border-gray-300 rounded-lg p-5">
                    <input type="text" class="w-1/3 py-2 px-1 text-center outline-none" v-model="versionMajor" placeholder="Major" />
                    <span>.</span>
                    <input type="text" class="w-1/3 py-2 px-1 text-center outline-none" v-model="versionMinor" placeholder="Minor" />
                    <span>.</span>
                    <input type="text" class="w-1/3 py-2 px-1 text-center outline-none" v-model="versionRevision" placeholder="Revision" />

                  </div>
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="versionMajor=='' || versionMinor=='' || versionRevision==''">필수 입력 사항 입니다.</p>
                </div>

                <div class="form-inp" v-else>
                  <div class="flex justify-center items-center border border-solid border-gray-300 rounded-lg p-5">
                    <input type="text" class="w-1/3 py-2 px-1 text-center outline-none" v-model="versionWifi" placeholder="Version" />
                  </div>
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="versionWifi==''">필수 입력 사항 입니다.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-gray size-lg" @click="closeFirmwareRegist()">닫기</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="firmwareRegist()" :disabled="checkRegistDisable()">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeFirmwareRegist()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import { richSelectXL } from "@/references/fixedClass"

export default {
  created () {
    this.getFirmwareInfo("device")
    this.getFirmwareInfo("wifi")
  },
  computed: {
    ...mapGetters(["getIsOverlay"]),
  },
  data () {
    return {
      richSelectXL,
      firmwareInfo: {
        "device": {
          "firmwareVersion": "0.0.0",
          "fileSize": 0,
          "insertDate": "0000-00-00 00:00:00",
          "fileOrgName": ""
        },
        "wifi": {
          "firmwareVersion": "0.0.0",
          "fileSize": 0,
          "insertDate": "0000-00-00 00:00:00",
          "fileOrgName": ""
        }
      },
      showFirmwareRegist: false,
      firmwareType: 'device',
      firmwareTypeList: [
        {id: 'device', value: '단말기 펌웨어'},
        {id: 'wifi', value: 'Wifi DB'},
      ],
      firmwareFileInfo: null,
      versionMajor: '',
      versionMinor: '',
      versionRevision: '',
      versionWifi: ''
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay"]),
    async getFirmwareInfo (firmwareType) {
      let reqObj = {}
      reqObj.target = `/firmware/info/${firmwareType}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData != null) this.firmwareInfo[firmwareType] = result.msg.retData;
      }
    },
    displayFirmwareRegist () {
      this.showFirmwareRegist = true
    },
    closeFirmwareRegist () {
      this.firmwareType = 'device'
      this.firmwareFileInfo = null
      this.versionMajor = ''
      this.versionMinor = ''
      this.versionRevision = ''
      this.versionWifi = ''
      this.showFirmwareRegist = false
    },

    changeFirmwareType () {
      this.versionMajor = ''
      this.versionMinor = ''
      this.versionRevision = ''
      this.versionWifi = ''
    },

    firmwareRegist () {
      this.firmwareRegistAction()
    },
    async firmwareRegistAction () {
      let reqObj = {}
      reqObj.target = `/firmware/regist`;
      reqObj.method = "post";
      reqObj.params = {};
      
      const frm = new FormData();
      
      let firmwareVersion = '';
      if(this.firmwareType == 'device') firmwareVersion = this.versionMajor + "." + this.versionMinor + "." + this.versionRevision;
      else firmwareVersion = this.versionWifi

      frm.append("firmwareDescription", '');
      frm.append("firmwareFileInput", this.firmwareFileInfo);
      frm.append("firmwareMajor", this.versionMajor ? this.versionMajor : 0);
      frm.append("firmwareMinor", this.versionMinor ? this.versionMinor : 0);
      frm.append("firmwareRevision", this.versionRevision ? this.versionRevision : 0);
      frm.append("firmwareType", this.firmwareType);
      frm.append("firmwareVersion", firmwareVersion);
      reqObj.data = frm;

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.versionMajor = ''
        this.versionMinor = ''
        this.versionRevision = ''
        this.firmwareFileInfo = null
        this.showFirmwareRegist = false

        this.getFirmwareInfo(this.firmwareType)
      }
    },

    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.firmwareFile.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    onChange() {
      this.firmwareFileInfo = this.$refs.firmwareFile.files[0];
    },
    registFirmwareFile () {
      this.onChange()
    },
    retryFirmwareFile () {
      this.firmwareFileInfo = null
    },
    checkRegistDisable () {
      if(this.firmwareFileInfo == null) return true;
      // if(this.firmwareType == 'device' && this.versionMajor =="" ||  this.versionMinor == "" || this.versionRevision == "") return true
      // if(this.firmwareType == 'wifi' && this.versionWifi =="") return true
      if(this.firmwareType == 'device') {
        if(this.versionMajor =="" ||  this.versionMinor == "" || this.versionRevision == "") return true;
      }
      else if(this.firmwareType == 'wifi') {
        if(this.versionWifi =="") return true
      }


      return false
    },
  }
  
}
</script>
<style lang="">
  
</style>